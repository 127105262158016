import React from "react"
import { Link } from "gatsby"
import "../tachyons.min.css"

class CategoryItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false }
	}
	render() {
		const category = this.props.item;
		return (
			<li className='db mb4'><Link activeClassName='black b f5' className='black-80 no-underline dim ttu tracked f6' to={ "/shop/" + category.slug + "/"}>{ category.name }</Link></li>
		)
	}
}

const CategoryList = (props) => (
	<ol className='ph0 ma0 list'>
		<li className='db mb4'><Link activeClassName='black b f5' className='black-80 no-underline dim ttu tracked f6' to={ "/shop/" } exact="true">All Products</Link></li>
		{
			props.items.map(category =>
				<CategoryItem key={ category.slug } item={category} />
			)
		}
	</ol>
)

export default CategoryList;