import React from 'react';

class ProductImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			has_error: false
		}
	}
	handleError = () => {
		this.setState({ has_error: true })
	}
	render() {
		let src = '/assets' + this.props.product.fields.path + '_' + this.props.idx + '.jpg';
		if(this.state.has_error) {
			// src = '/assets/drink_stirrer_1_1.jpg';
			return null;
		}
		return (
			<div className='relative'>
				<img onError={ this.handleError } src={ src } />
				{ this.props.banner_price && this.props.banner_price !== 0 &&
					<p className='absolute bottom-0 right-0 bg-black-80 f5 white ph2 pv2 br1-left'>${ this.props.banner_price }</p>
				}
			</div>
		)
	}
}

export default ProductImage;