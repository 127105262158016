import React from "react"
import "../tachyons.min.css"

export default (props) => (
	<div className='pv3 pv4-ns mb2-m pv5-l cf'>
		<div className='w-100 w-40-ns fl'>
			<h2 className='arvo fw4 f5 f4-m f4-l f5-xl dib black-90 bb bw2 b--black-20 pb1 br1 ma0 pa0'>{ props.title }</h2>
			{ props.subtitle &&
				<span className='black-50 pl2 dib dn-ns'>/ { props.subtitle }</span>
			}
		</div>
		<div className='w-100 w-60-ns fl pv2'>
			{ props.children }
		</div>
	</div>
);