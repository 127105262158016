import React from "react"
import { Link } from "gatsby"
import "../tachyons.min.css"

class CategoryItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false }
	}
	render() {
		const category = this.props.item;
		return (
			<li className='mb3'>
				<Link activeClassName='black b' className='black-80 no-underline dim ttu tracked f6' to={ "/shop/" + category.slug }>{ category.name }</Link>
			</li>
		)
	}
}

class CategoryDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}
	}
	toggle = () => {
		const open = this.state.open;
		this.setState({
			open: !open
		})
	}
	render() {
		return (
			<div className='ba b--black-90 dim pointer fadeInUp br1' onClick={ this.toggle }>
				<p className='ttu tracked f6 black-80 ma0 pa0 pa3'>
					Category: <span className='b'>{ this.props.current }</span>
				</p>
				{ this.state.open &&
					<ol className='ma0 list bt b--black-90 pt3 ph3 pb0'>
						<li className='mb3'><Link activeClassName='black b' className='black-80 no-underline dim ttu tracked f6' to={ "/shop" } exact>All Products</Link></li>
						{
							this.props.items.map(category =>
								<CategoryItem key={ category.slug } item={category} />
							)
						}
					</ol>
				}
			</div>
		)
	}
}

export default CategoryDropdown;