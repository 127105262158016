import React from "react";
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import ProductList from "../components/product-list"
import CategoryList from "../components/category-list"
import CategoryDropdown from "../components/category-dropdown"
import { FreeShippingWhite } from "../components/free-shipping"
import "../style.css"
import _sortBy from "lodash/sortBy";
import _uniqBy from "lodash/uniqBy";
import _filter from "lodash/filter";
import _ from "lodash";

class Category extends React.Component {
  render() {
    const data = this.props.data;
    const categories = _sortBy(data.categories.edges.map(({node}) => node), 'name');
    let products;
    if(data.products !== null) {
      products = data.products.edges.map(({node}) => node );
      if(data.category.name === 'Drink Stirrers') {
        // products = _uniqBy(products, 'name');
        // debugger;
        let mapped;
        mapped = _(products).map(prod => {
          prod.name = prod.name + " (Set of 6)";
          return prod;
        }).uniqBy('name').value();
        products = mapped;
      }
    }
    return (
      <Layout>
        <div className='ph3 ph4-ns pt3 pt0-ns'>
          <PageTitle title="Shop" />
          <div className='cf'>
            <div className='w-100 dn-l dn-xl pb4 fl'>
              <CategoryDropdown current={ data.category.name } items={ categories } />
            </div>
            <div className='dn db-m db-l db-xl w-30-m w-25-l w-20-xl pv2 pv0-ns fl'>
              <CategoryList items={ categories } />
            </div>
            <div className='w-100 w-70-m w-75-l w-80-xl fl pb4'>
              { data.products === null &&
                <p className='black-90 mb4 i f5'>No products yet.</p>
              }
              { data.products !== null &&
                <div>
                  <div className='tr'>
                    <div className='dib mb4'>
                      <FreeShippingWhite />
                      <p className='f7 pa0 ma0 mt2'><Link className='blue no-underline dim' to="/faq">Some exclusions apply.</Link></p>
                    </div>
                  </div>
                  <ProductList items={ products }  />
                </div>
              }
            </div>
          </div>
        </div>
      </Layout>
    );
  }
};

export const query = graphql`
  query CategoryQuery($category_slug: String!) {
    category: airtableCategories(slug: { eq: $category_slug }) {
      name
      slug
    }
    categories: allAirtableCategories {
      edges {
        node {
          name
          slug
        }
      }
    }
    products: allAirtableProducts( filter: { active: {eq: true }, fields: { category_slug: {eq: $category_slug } }}) {
      totalCount
      edges {
        node {
          name
          active
          category
          material
          colors
          lowest_price
          base_price
          premium_price
          active
          fields {
            path
            slug
            category_slug
            vanity_name
          }
        }
      }
    }
  }
`;

export default Category;