import React from "react"
import { Link } from "gatsby"
import ProductImage from "../components/product-image"
import _indexOf from "lodash/indexOf"

class ProductList extends React.Component {
	findEdges(width, count) {
		const per_row = parseInt(100/width);
		const edges = [];
		for(var i = 0; i < per_row; i++) {
			let start = i * per_row;
			edges.push(start);
			edges.push(start + (per_row - 1));
		}
		return edges;
	}
	determinePadding(idx, edges) {
		const edge_idx = _indexOf(edges, idx);
		if(edge_idx % 2 === 0) {
			return 'ph3-ns';
		}
		else if(edge_idx % 2 === 1) {
			return 'ph3-ns';
		}
		return 'ph3-ns';
	}
	render() {
		const self = this;
		const width = this.props.width || '50';
		const edges = this.findEdges(width, this.props.items.length);
		const items = this.props.items.map((item, idx) => {
			const multiple_prices = item.base_price !== item.premium_price;
			const banner_price = multiple_prices ? ( item.lowest_price + "+" ) : item.lowest_price + "\xa0";
			return (
				<Link to={'/shop/' + item.fields.category_slug + item.fields.path } className={ 'border-box no-underline pointer w-100 mb4 w-' + width + '-ns w-50-m w-50-l v-top w-third-xl dib pb3 ph3-ns' } style={{ minHeight: '370px' }} key={idx}>
					<li key={ idx } className='dim pointer'>
						<ProductImage product={ item } idx={ 1 } banner_price={ banner_price } />
						<p className='ma0 pa0 pt3 f4 fw4 black-90 lh-copy'>{ item.fields.vanity_name }</p>
						<span className='dib pt2 f6 black-30 '>{ item.material }, { item.colors.length } { item.colors.length === 1 ? 'color' : 'colors' }</span>
					</li>
				</Link>
			)
		})
		return (
			<ul className='list pa0 ma0 nh3-ns cf'>
				{ items }
			</ul>
		)
	}
}

export default ProductList;
