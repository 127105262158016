import React from "react"
import Icon from "../components/icon"

const FreeShippingBlack = (props) => (
	<div className='bg-black-10 pa3 br2 black-90 mt4 mt5-ns w-100 w-70-ns'>
		<Icon className='dib db-ns mr3 v-mid black-20 anim-rotate-slow' icon={ 'star' } style={{ width: 18, height: 18 }} />
		<p className='ma0 pa0 dib db-ns mt2-ns v-mid ttu tracked f6'>Free shipping</p>
		<p className='f6 mt3 pt1 mb0'>Standard shipping is <span className='b'>free</span> for orders over $100.</p>
	</div>
)


const FreeShippingWhite = (props) => (
	<div className='ba b--black-20 pa3 br2 black-90'>
		<Icon className='dib mr3 v-mid black-80' icon={ 'alert-circle' } style={{ width: 18, height: 18 }} />
		<p className='dib f6 ma0 pa0 mt3 mt0-ns v-mid'><span className='b'>Standard shipping is free</span> for orders over $100.*</p>
	</div>
)


const FreeShippingSlim = (props) => (
	<div className='ba b--black-20 pa3 br2 black-80 dib-ns'>
		<Icon className='dib mr3 v-mid black-80' icon={ 'alert-circle' } style={{ width: 18, height: 18 }} />
		<p className='ma0 pa0 dib mt3 mt0-ns v-mid f6 lh-copy'>{ props.children }</p>
	</div>
)

export { FreeShippingWhite, FreeShippingSlim };